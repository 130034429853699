<template>
  <div v-show="!showModal">
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <a-row>
          <a-form-item
            class="ui-form__item"
            label="影院组织"
            name="organizationId"
          >
            <a-select
              placeholder="请选择"
              v-model:value="formState.organizationId"
              style="width: 180px"
              @change="getAllCinemaList"
            >
              <!-- <a-select-option :value="0">全部</a-select-option> -->
              <a-select-option
                v-for="item in organizationList"
                :vlaue="item.id"
                :key="item.id"
              >
                {{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>

          <a-form-item class="ui-form__item" label="所属影院" name="cinemaId">
            <a-select
              placeholder="请选择"
              v-model:value="formState.cinemaId"
              style="width: 180px"
            >
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option
                v-for="item in cinemaAllList"
                :vlaue="item.id"
                :key="item.id"
              >
                {{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-row>

        <a-row v-show="isShow">
          <a-form-item class="ui-form__item" label="会员昵称" name="nickname">
            <a-input
              placeholder="请输入"
              v-model:value="formState.nickname"
              style="width: 180px"
            />
          </a-form-item>

          <a-form-item class="ui-form__item" label="会员手机号" name="phone">
            <a-input
              placeholder="请输入"
              v-model:value="formState.phone"
              style="width: 180px"
            />
          </a-form-item>

          <a-form-item
            class="ui-form__item"
            label="是否关注"
            name="isSubscribe"
          >
            <a-select
              placeholder="请选择"
              style="width: 180px"
              v-model:value="formState.isSubscribe"
            >
              <a-select-option :value="1">已关注</a-select-option>
              <a-select-option :value="0">未关注</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            class="ui-form__item"
            label="是否添加企微客服"
            name="addService"
          >
            <a-select
              placeholder="请选择"
              style="width: 180px"
              v-model:value="formState.addService"
            >
              <a-select-option :value="1">已添加</a-select-option>
              <a-select-option :value="0">未添加</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item class="ui-form__item" label="状态" name="isDisabled">
            <a-select
              placeholder="请选择"
              style="width: 180px"
              v-model:value="formState.isDisabled"
            >
              <a-select-option :value="1">冻结</a-select-option>
              <a-select-option :value="0">正常</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <span v-if="!isShow" class="ui-showMore" @click="onDisplay"
              >展开<Icon icon="DownOutlined"></Icon
            ></span>
            <span v-else class="ui-showMore" @click="onDisplay"
              >收起<Icon icon="UpOutlined"></Icon
            ></span>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
              style="margin-right: 10px"
              type="primary"
              html-type="submit"
              >搜索</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px">
        <a-table
          rowKey="id"
          :pagination="pagination"
          :columns="columns"
          :dataSource="list"
          :scroll="{ x: 2500 }"
          tableLayout="auto"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key == 'avatar'">
              <a-image :width="40" :src="record.avatar" />
            </template>

            <template v-if="column.key == 'isSubscribe'">
              <div
                :class="
                  record.isSubscription == 1 ? 'ui-follow' : 'ui-unFollow'
                "
              >
                {{ transFollow(record.isSubscription) }}
              </div>
            </template>

            <template v-if="column.key == 'addService'">
              <div
                :class="record.addService == 1 ? 'ui-follow' : 'ui-unFollow'"
              >
                {{ transAdd(record.addService) }}
              </div>
            </template>

            <template v-if="column.key == 'isDisabled'">
              <div
                :class="record.isDisabled == 0 ? 'ui-follow' : 'ui-unFollow'"
              >
                {{ record.isDisabled == 0 ? "正常" : "冻结" }}
              </div>
              <div v-if="record.isDisabled == 1">{{ record.reason }}</div>
            </template>

            <template v-if="column.key == 'cinema'">
              <div>{{ record.cinema || "--" }}</div>
            </template>

            <template v-if="column.key == 'phone'">
              <div>{{ record.phone || "--" }}</div>
            </template>

            <template v-if="column.key == 'nickname'">
              <div>{{ record.nickname || "--" }}</div>
            </template>

						<template v-if="column.key == 'distributionTitle'">
							<div>{{record.distributionTitle || '--'}}</div>
						</template>

						<template v-if="column.key == 'cardInfo'">
							<div slot="cardInfo-slot" slot-scope="text, record">
								<div>年卡：{{record.yearCardNum || 0}}张</div>
								<div>次数卡：{{record.timesCardNum || 0}}张</div>
								<div>线上会员卡：{{ record.rechargeCardNum || 0 }}张</div>
							</div>
						</template>

						<template v-if="column.key == 'couponInfo'">
							<div slot="couponInfo-slot" slot-scope="text, record">
								 <!-- <div>兑换券(旧)：{{record.coinCertificateNum || 0}}张</div> -->
								<div>兑换券：{{record.discountCouponNum || 0}}张</div>
								<div>卖品券：{{record.snackTicketNum || 0}}张</div>
							</div>
						</template>

            <template v-if="column.key == 'goodsPrice'">
              <div slot="goodsPrice-slot" slot-scope="text, record">
                <div>{{ record.countByGoods || 0 }}元</div>
              </div>
            </template>

            <template v-if="column.key == 'ticketPrice'">
              <div slot="ticketPrice-slot" slot-scope="text, record">
                <div>{{ record.countByCinema || 0 }}元</div>
              </div>
            </template>

            <template v-if="column.key == 'oneLevelScale'">
              <div>{{ record.oneLevelScale || 0 }}元</div>
            </template>

            <template v-if="column.key == 'twoLevelScale'">
              <div>{{ record.twoLevelScale || 0 }}元</div>
            </template>

            <template v-if="column.key == 'createTime'">
              <div>{{ transDateTime(record.createTime) }}</div>
            </template>

            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button @click.prevent
                  >操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div>
                      <a-menu-item @click="onDetail(record)">
                        详情
                      </a-menu-item>
                    </div>
                    <div
                      v-permission="['member_manage_info_user']"
                      @click="onUser(record)"
                    >
                      <a-menu-item> 修改推荐人 </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>

      <a-modal
        v-model:visible="showReferrerModal"
        destroyOnClose
        title="修改推荐人"
        width="800px"
      >
        <template #footer>
          <a-button type="primary" @click="showReferrerModal = false"
            >关闭</a-button
          >
        </template>
        <userList
          :id="id"
          :recommendId="recommendId"
          :recommendInfo="recommendInfo"
          @onRef="getData"
        ></userList>
      </a-modal>
    </a-spin>
  </div>
  <temp v-if="showModal" :id="id" @back="onBack"></temp>
</template>

<script>
import temp from "./temp.vue";
import { Icon } from "@/components/icon/icon.js";
import userList from "@/views/member/distributor/list/userList.vue";
import { getMemberList } from "@/service/modules/member.js";
import {
  getOrganizationList,
  getCinemaList,
} from "@/service/modules/cinema.js";
export default {
  components: {
    Icon,
    temp,
    userList,
  },
  data() {
    return {
      loading: false,
      formState: {},
      showModal: false,
      showReferrerModal: false,
      recommendId: 0,
      id: 0,
      list: [],
      columns: [
        {
          title: "会员手机号",
          dataIndex: "phone",
          key: "phone",
        },
        {
          title: "会员昵称",
          dataIndex: "nickname",
          key: "nickname",
        },
        {
          title: "头像",
          key: "avatar",
        },
        // {
        // 	title: '是否关注',
        // 	key: 'isSubscribe'
        // },
        {
          title: "是否添加企微客服",
          key: "addService",
        },
        {
          title: "状态",
          key: "isDisabled",
        },
        {
          title: "卡信息",
          key: "cardInfo",
          scopedSlots: { customRender: "cardInfo-slot" },
        },
        {
          title: "券信息",
          key: "couponInfo",
          scopedSlots: { customRender: "couponInfo-slot" },
        },
        {
          title: "累计消费商品金额",
          key: "goodsPrice",
          scopedSlots: { customRender: "goodsPrice-slot" },
        },
        {
          title: "累计消费影票金额",
          key: "ticketPrice",
          scopedSlots: { customRender: "ticketPrice-slot" },
        },
        {
          title: "分销等级",
          key: "distributionTitle",
          dataIndex: "distributionTitle",
        },
        {
          title: "自购返佣",
          key: "oneLevelScale",
        },
        {
          title: "邀请人返佣",
          key: "twoLevelScale",
        },
        {
          title: "注册时间",
          key: "createTime",
        },
        {
          title: "当前影院",
          key: "cinema",
        },
        {
          title: "操作",
          key: "action",
          width: 100,
          fixed: "right",
        },
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        },
      },
      isEdit: false,
      id: 0,
      searchData: [],
      organizationList: [],
      cinemaAllList: [],
      isShow: false,
      recommendInfo: {},
    };
  },
  created() {
    // this.getData();
    this.getOrganizationList();
  },
  methods: {
    onBack() {
      this.getData();
      this.showModal = false;
    },
    onDetail(record) {
      this.showModal = true;
      this.id = record.id;
    },
    onUser(item) {
      this.showReferrerModal = true;
      this.recommendId = item.recommendId;
      this.recommendInfo = {
        recommendNickname: item.recommendNickname || "",
        recommendAvatar: item.recommendAvatar || "",
        recommendPhone: item.recommendPhone || "",
      };
      this.id = item.id;
    },
    onSearch() {
      this.pagination.current = 1;
      // //this.pagination.pageSize = 10;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    reset() {
      this.$refs.form.resetFields();
      if (this.organizationList.length) {
        this.formState.organizationId = this.organizationList[0].id;
        this.getAllCinemaList(this.formState.organizationId);
      }
      this.onSearch();
    },
    async getData() {
      const postData = {
        page: this.pagination.current,
        pageSize: this.pagination.pageSize,
        ...this.searchData,
      };
      this.loading = true;
      try {
        let { data } = await getMemberList(postData);
        this.loading = false;
        this.list = data.list;
        this.pagination.total = data.totalCount;
      } catch (error) {
        this.loading = false;
      }
    },
    onDisplay() {
      this.isShow = !this.isShow;
    },
    transFollow(type) {
      const res = {
        0: "未关注",
        1: "已关注",
      };
      return res[type];
    },
    transAdd(type) {
      const res = {
        0: "未添加",
        1: "已添加",
      };
      return res[type];
    },
    async getOrganizationList() {
      this.loading = true;
      try {
        let ret = await getOrganizationList({
          page: 1,
          pageSize: 999999,
        });
        if (ret.code === 200) {
          this.organizationList = ret.data.list;
          if (this.organizationList.length) {
            this.formState.organizationId = this.organizationList[0].id;
            this.getAllCinemaList(this.formState.organizationId);
          }
          this.onSearch();
        } else {
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async getAllCinemaList(organizationId) {
      this.cinemaAllList = [];
      this.formState.cinemaId = "";
      let ret = await getCinemaList({
        page: 1,
        pageSize: 999999,
        organizationId: organizationId ? organizationId : undefined,
      });
      if (ret.code === 200) {
        this.cinemaAllList = ret.data.list;
      }
    },
  },
};
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
.ui-batch {
  margin-right: 10px;
}
.ui-showMore {
  color: #6699ff;
  cursor: pointer;
}
.ui-unFollow {
  color: #ff0000;
}
.ui-follow {
  color: rgb(103, 194, 58);
}
</style>
